/* eslint quotes: ["error", "single"] */
/* eslint quote-props: ["error", "always"] */
module.exports = {
  'A ferramenta que te ajuda a monitorar a saúde da sua escola': 'A ferramenta que te ajuda a monitorar a saúde da sua escola',
  'Acessar': 'Acessar',
  'Envio de enquetes pelo ClassApp': 'Envio de enquetes pelo ClassApp',
  'Envie enquetes para os pais periodicamente de forma automática': 'Envie enquetes para os pais periodicamente de forma automática',
  'Contribua no monitoramento': 'Contribua no monitoramento',
  'Acompanhe a saúde de sua escola e contribua para o monitoramento do bem-estar social': 'Acompanhe a saúde de sua escola e contribua para o monitoramento do bem-estar social',
  'Monitore a saúde de sua escola': 'Monitore a saúde de sua escola',
  'Acompanhe informações relevantes sobre seus alunos': 'Acompanhe informações relevantes sobre seus alunos',
  'Acompanhe a saúde da sua escola, e contribua conosco para o bem-estar social': 'Acompanhe a saúde da sua escola, e contribua conosco para o bem-estar social',
  'Entrar com o ClassApp': 'Entrar com o ClassApp',
  'Perfil': 'Perfil',
  'Escola': 'Escola',
  'Status': 'Status',
  'Frequência (Envio)': 'Frequência (Envio)',
  'Salvar': 'Salvar',
  'Configurar': 'Configurar',
  'O Monitor ainda não está ativo!': 'O Monitor ainda não está ativo!',
  'Clique em configurar para ativar.': 'Clique em configurar para ativar.',
  'descricao card respostas': 'Neste painel você encontra a quantidade de respostas à enquete em relação ao total de alunos e/ou funcionários cadastrados na instituição. Esses dados consideram apenas as respostas da semana selecionada.',
  'descricao card casos': 'Neste painel você encontra a quantidade absoluta de alunos e/ou funcionários que apresentaram casos de COVID em seu grupo familiar na semana selecionada e o percentual que esse número representa em relação ao total de alunos e/ou funcionários da instituição.',
  'descricao card sintomas': 'Neste painel você encontra a quantidade absoluta de grupos familiares em que algum membro apresentou sintomas gripais (febre, tosse e/ou dificuldade para respirar) na última semana e o percentual que esse número representa em relação ao total de alunos e/ou funcionários da instituição.',
  'descricao card contatos': 'Neste painel você encontra a quantidade absoluta de grupos familiares em que algum membro teve contato com casos confirmados de COVID na última semana e o percentual que esse número representa em relação ao total de alunos e/ou funcionários da instituição.',
  'descricao grafico de area': 'Neste gráfico você encontra o registro histórico dos dados coletados até a data selecionada no painel superior. Neste histórico, são mostrado os valores absolutos para casos confirmados, sintomas e contato direto com pessoas contaminadas, organizados semana a semana. Além disso, no eixo horizontal, é possível conferir o percentual de famílias e/ou funcionários que respondeu as mensagens das respectivas semanas. Flutuações nos valores reportados podem ocorrer em função da maior ou menor participação da comunidade na enquete do respectivo período.',
  'descricao grafico de pizza': 'Neste gráfico você encontra o percentual de famílias e/ou funcionários “seguros”. Para este cálculo, consideram-se “seguros” aqueles que não têm casos confirmados no grupo familiar, não apresentam sintomas nem tiveram contato com pessoas contaminadas.',
  'descricao filtro data': 'Ao selecionar uma data, você terá um recorte que representa a situação para a semana escolhida. Por padrão, o Monitor do Bem-estar exibe a semana mais recente para a qual houve a coleta de dados.',
  'descricao filtro grupos': 'Ao selecionar um ou mais grupos, você terá um recorte que representa apenas as respostas informadas por alunos/funcionários que pertencem aquele grupo.',

};
