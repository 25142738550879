import React from 'react';

const Loading = ({ width, height }) => (
  <div
    className="loader"
    style={{
      height: `${height}px`,
      width: `${width}px`
    }}
  />
);

export default Loading;
