
const Config = {
  name: 'Monitor do Bem-estar',
  joyApi: {
    uri: {
      production: 'https://web.classapp.com.br/graphql',
      stage: 'https://web-stage.classapp.ninja/graphql',
      development: 'https://web.classapp.com.br/graphql',
    }
  },
  walle: {
    uri: {
      production: 'https://classapp.co/',
      stage: 'https://classapp.ninja/',
      development: 'http://localhost:5400/',
    }
  },
  authClientId: 'a66438621ff595562f0907b4e180afb04d95457efd3e449be34b4c539a89955e',
  installClientId: '4685c0caa1f97282974fb876ac5809ad4845d6ba0afe17a4775a4936cf535ec3',
  loginRedirect: {
    development: 'http://localhost:3000/dashboard',
    stage: 'https://drix.classapp.ninja/dashboard',
    production: 'https://monitor.classapp.com.br/dashboard'
  },
  installRedirect: {
    development: 'http://localhost:3000/install',
    stage: 'https://drix.classapp.ninja/install',
    production: 'http://monitor.classapp.com.br/install'
  },
  assets: {
    development: '/static/',
    stage: 'https://drix-stage-assets.s3.us-west-2.amazonaws.com/assets/',
    production: 'https://drix-production-assets.s3.us-west-2.amazonaws.com/assets/'
  }
};

Config.loginUri = {
  development: `http://localhost:5400/oauth/authorize?client_id=${Config.authClientId}&redirect_uri=${Config.loginRedirect.development}`,
  stage: `http://classapp.ninja/oauth/authorize?client_id=${Config.authClientId}&redirect_uri=${Config.loginRedirect.stage}`,
  production: `http://classapp.com.br/oauth/authorize?client_id=${Config.authClientId}&redirect_uri=${Config.loginRedirect.production}`,
};

Config.installUri = {
  development: `http://localhost:5400/oauth/authorize?client_id=${Config.installClientId}&redirect_uri=${Config.installRedirect.development}`,
  stage: `http://classapp.ninja/oauth/authorize?client_id=${Config.installClientId}&redirect_uri=${Config.installRedirect.stage}`,
  production: `http://classapp.com.br/oauth/authorize?client_id=${Config.installClientId}&redirect_uri=${Config.installRedirect.production}`,
};

export default Config;
