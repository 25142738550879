import React from 'react';
import FadeIn from 'react-fade-in';

import Meta from '../components/Meta';
import HeadMenu from '../components/HeadMenu';

const Main = ({
  children, title, metaTags, className, noHeader
}) => (
  <FadeIn>
    <Meta tags={metaTags} title={title} />
    {noHeader ? <div /> : <HeadMenu />}
    <div className={className}>
      {children}
    </div>
  </FadeIn>
);

export default Main;
