/* eslint react/no-danger: 0 */

import React from 'react';
import Head from 'next/head';

import NProgress from 'nprogress';
import Router from 'next/router';

import Config from '../lib/Config';
import { asset } from '../lib/Util';

NProgress.configure({ showSpinner: false });

Router.onRouteChangeStart = (url) => {
  console.log(`Loading: ${url}`);
  NProgress.start();
};
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();

const Meta = ({ title, tags }) => (
  <Head>
    <title>{(title ? title + ' - ' : '') + Config.name}</title>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-119159656-1" />
    <script dangerouslySetInnerHTML={{
      __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-119159656-1');`
    }}
    />
    <link rel="icon" type="image/x-icon" href={asset('favicon.ico')} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <link rel="stylesheet" href="https://assets.classapp.co/semantic/semantic.min.css" />
    <link rel="stylesheet" type="text/css" href={asset('nprogress.css')} />

    <style dangerouslySetInnerHTML={{
      __html: `
        @font-face {
          font-family: "CircularStd-Book";
          src: url("${asset('fonts/CircularStd-Book.otf')}") format("truetype");
        }

        @font-face {
          font-family: "CircularStd-Bold";
          src: url("${asset('fonts/CircularStd-Bold.otf')}") format("truetype");
        }

        @font-face {
          font-family: "CircularStd-Medium";
          src: url("${asset('fonts/CircularStd-Medium.otf')}") format("truetype");
        }
      `
    }}
    />

    {tags}
  </Head>
);

export default Meta;
