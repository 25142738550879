/* eslint no-underscore-dangle: 0*/
/* eslint global-require: 0*/

const { sprintf } = require('sprintf-js');

const i18n = {};

i18n.locale = 'pt';

i18n.translations = {
  pt: require('../locales/pt'),
  pt_BR: require('../locales/pt'),
  en: require('../locales/en'),
  en_US: require('../locales/en'),
};

i18n.languages = [
  { text: 'Português', value: 'pt' },
  { text: 'English', value: 'en' },
  //{ text: 'Português [PT]', value: 'pt_PT' }
];

i18n.__ = function (str, ...other) {
  const currentLocale = i18n.currentLocale();

  if (i18n.translations[currentLocale][str]) {
    return sprintf(i18n.translations[currentLocale][str], ...other);
  }
  return sprintf(str, ...other);
};

function getCookie(cname) {
  if (typeof document == 'undefined' || !document.cookie) return null;
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

i18n.currentLocale = function () {
  let { locale } = i18n;
  //console.log('locale', locale);
  /* if (navigator) {
    locale = (navigator.userLanguage || navigator.browserLanguage || navigator.language).substr(0, 2);
  }
  console.log('locale', locale); */
  locale = getCookie('lang');

  const locales = Object.keys(i18n.translations);
  if (!locale || typeof locale !== 'string' || locales.indexOf(locale) == -1) return locales[0];

  if (locale.length > 2) {
    locale = locale.substring(0, 2);
  }

  if (locales.indexOf(locale) === -1) {
    return locales[0];
  }

  return locale;
};


i18n.toISO6392 = (l) => {
  const langs = {
    de: 'deu',
    fi: 'fin',
    fr: 'fra',
    hr: 'hrv',
    it: 'ita',
    ja: 'jpn',
    nl: 'nld',
    pt: 'por',
    ru: 'rus',
    es: 'spa'
  };
  return langs[l];
};

module.exports = i18n;
