/* eslint quotes: ["error", "single"] */
/* eslint quote-props: ["error", "always"] */
module.exports = {
  'A ferramenta que te ajuda a monitorar a saúde da sua escola': 'The tool that helps you monitor the health of your school',
  'Acessar': 'Access',
  'Envio de enquetes pelo ClassApp': 'Sending polls through ClassApp',
  'Envie enquetes para os pais periodicamente de forma automática': 'Send surveys to parents periodically automatically',
  'Contribua no monitoramento': 'Contribute to monitoring',
  'Acompanhe a saúde de sua escola e contribua para o monitoramento do bem-estar social': 'Follow the health of your school and contribute to the monitoring of social welfare',
  'Monitore a saúde de sua escola': 'Monitor your school\'s health',
  'Acompanhe informações relevantes sobre seus alunos': 'Track relevant information about your students',
  'Acompanhe a saúde da sua escola, e contribua conosco para o bem-estar social': 'Follow the health of your school, and contribute with us to the social welfare',
  'Entrar com o ClassApp': 'Sign in with ClassApp',
  'Grupo de Exclusão da pesquisa: ': 'Research Group exclusion: ',
  'Grupo de Funcionários: ': 'Employee Group: ',
  'Perfil': 'Profile',
  'Escola': 'School',
  'Status': 'Status',
  'Painel': 'Dashboard',
  'Sair': 'Sign out',
  'Frequência (Envio)': 'Frequency (Survey delivery)',
  'Salvar': 'Save',
  'Configurar': 'Configure',
  'O Monitor ainda não está ativo!': 'The monitor is not active yet!',
  'Clique em configurar para ativar.': 'Click on configure to activate.',
  'Casos confirmados': 'Confirmed cases',
  'Exposição a casos confirmados': 'Exposed to confirmed cases',
  'Sintomáticos no grupo familiar': 'Symptomatic in the family group',
  'Seguros': 'Safe',
  'Não responderam': 'Did not answer',
  'Registros': 'Records',
  'Histórico de dados': 'Data history',
  'Percentual de grupos familiares seguros': 'Percentage of safe family groups',
  '%s Seguros': '%s Safe',
  'descricao card respostas': 'In this panel you can find the number of answers to the survey compared to the number of students subscribed to your institution. Only answers from the selected week is considered.',
  'descricao card casos': 'In this panel you can find the absolute number of students that have presented cases of COVID in their family on the selected week and the percentage that this number represents in relation to the total number of students in the institution.',
  'descricao card sintomas': 'In this panel you find the absolute number of family groups in which a member has had flu-like symptoms (fever, cough and/or difficulty for breathing) in the last week and the percentage that this number represents in relation to the total number of students at the institution.',
  'descricao card contatos': 'In this panel you can find the absolute number of family groups in which a member had contact with confirmed cases of COVID in the last week and the percentage that this number represents in relation to the total number of students at the institution.',
  'descricao grafico de area': 'In this graph you can find the historical record of the data collected up to the date selected in the top panel. In this history, the absolute values for confirmed cases, symptoms and direct contact with infected people are shown, organized week by week. In addition, on the horizontal axis, it is possible to check the percentage of families that responded to the messages of the respective weeks and fluctuations in the reported values may occur due to the greater or lesser participation of parents in the survey of the respective period. Up to %s weeks prior to the selected date will be displayed.',
  'descricao grafico de pizza': 'In this graph you can find the percentage of “safe” families. For this calculation, those who do not have confirmed cases in the family group, have no symptoms or have had contact with infected people are considered “safe”.',
  'descricao filtro data': 'When selecting a date, you will have a cutout that represents the situation for the chosen week. By default, the Monitor do Bem-estar displays the most recent week for which data was collected.',
  'descricao filtro grupos': 'When selecting one or more groups, you will have a cutout that represents only the responses reported by students / staff who belong to that group.',
  'Usuário não pertence a nenhuma organização': 'User does not belong to any organization',
  '%s respondeu': '%s answered',
  'Data selecionada:': 'Selected date:',
  'Selecione a data': 'Select a date',
  'Escolas': 'Schools',
  'Opções': 'Options',
  'Grupos Selecionados: %s': 'Selected Groups: %s',
  'Configuração': 'Settings',
  'Acompanhamento Semanal': 'Weekly Report',
  'Grupo de Exclusão da pesquisa': 'Group excluded from research',
  'Criar no ClassApp': 'Create on ClassApp',
  'Ativado': 'Enabled',
  'Desativado': 'Disabled',
  'Dia de Envio: %s': 'Survey day: %s',
  'Domingo': 'Sunday',
  'Segunda-Feira': 'Monday',
  'Terça-Feira': 'Tuesday',
  'Quarta-Feira': 'Wednesday',
  'Quinta-Feira': 'Thursday',
  'Sexta-Feira': 'Friday',
  'Sábado': 'Satuday',
  '%s registros': '%s records',
  '1 registro': '1 record',
  'Ninguém': 'No one',
  '%s de %s': '%s of %s',
  'Período: Semanal': 'Frequency: Weekly',
  'Instalar no ClassApp': 'Install on ClassApp',
  'Período de pesquisa: de %s a %s': 'Research period: from %s until %s',
  'Número de registros': 'Number of records',
  'Data da enquete': 'Survey date',
};
