import moment from 'moment';
import unidecode from 'unidecode';
import axios from 'axios';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

import Config from './Config';
import { getNodeEnv, getOzzyApiEndpoint } from './env';
import { currentLocale } from './I18n';


export const initials = (name) => {
  const colors = [
    '#00875A',
    '#025EDB',
    '#2882FF',
    '#0BAE6D',
    '#00C7E6',
    '#FBB13C',
    '#ED3360',
    '#ED1C25',
    '#FF7226',
    '#6555C0',
  ];

  let firstLetter = 'a';
  let lastLetter = null;

  if (name) {
    const names = name.split(' ');
    const l = names.length;
    firstLetter = unidecode(names[0].charAt(0).toLowerCase()).replace(/[\u0300-\u036f]/g, '');
    lastLetter = (l > 1) && unidecode(names[l - 1].charAt(0).toLowerCase()).replace(/[\u0300-\u036f]/g, '');
  }

  const colorString = (firstLetter + (lastLetter || '')).replace(/\w/g, text => text.charCodeAt().toString(10));
  const color = colors[colorString.charAt(colorString.length - 1)];

  return {
    firstLetter, lastLetter, color
  };
};

export const percent = (numerator, denominator) => {
  if (denominator === 0 || typeof numerator !== 'number') return '0.0%';
  return ((numerator / denominator) * 100).toLocaleString(currentLocale(), {
    minimumFractionDigits: 1, maximumFractionDigits: 1
  }) + '%';
};

export const simpleDate = (date, time = false, format = null) => {
  const now = moment();
  //moment.locale(locale);
  let then = moment(date);
  const diff = then.diff(now, 'hours');

  then = then.utcOffset(now.utcOffset());

  if (format) {
    return then.format(format);
  }

  if (diff > -12 && diff < 12 && time) {
    return then.format('LT');
  } if (diff > -4380 && diff < 4380) { //24 * 365/2
    return then.format('MMM D');
  }

  return then.format('L');
};

export const classAppInstance = (token = null, install = false) => axios.create({
  baseURL: `${Config.joyApi.uri[getNodeEnv()]}?client_id=${install ? Config.authClientId : Config.installClientId}${token ? '&access_token=' + token : ''}`,
});

export const ozzyInstance = (token = null) => axios.create({
  baseURL: getOzzyApiEndpoint(),
  headers: token ? { Authorization: `Bearer ${token}` } : null
});

export const logout = (context) => {
  destroyCookie(context, 'accessToken');
  destroyCookie(context, 'access_token'); // < workaround, because joy sets this unused cookie
};

export const asset = path => Config.assets[getNodeEnv()] + path;

export const groupPath = path => Config.walle.uri[getNodeEnv()] + path;
