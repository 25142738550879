import React from 'react';
import {
  Container, Menu, Image, Dropdown, Icon
} from 'semantic-ui-react';

import { inject, observer } from 'mobx-react';

import FadeIn from 'react-fade-in';
import Avatar from 'react-avatar';
import { withRouter } from 'next/router';

import Loading from './Loading';
import { asset } from '../lib/Util';
import { __ } from '../lib/I18n';

// import { ozzyInstance, logout } from '../lib/Util';

const userTrigger = user => (
  <span
    style={{
      color: '#343738',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Avatar
      size={28}
      name={user.name}
      src={user.image}
      maxInitials={2}
      round="50%"
      style={{ marginRight: '5px' }}
    />
      &nbsp;
    <span className="name">{user.name}</span>
  </span>
);

const userOptions = (pathname, user) => [
  {
    key: 'profile',
    selected: pathname === '/profile',
    as: 'a',
    href: '/profile',
    icon: 'cog',
    text: __('Configuração')
  },
  {
    key: 'sign-out',
    selected: false,
    as: 'a',
    href: '/login?logout=1',
    icon: 'sign out',
    text: __('Sair')
  }
];

const organizationOptions = (selectedOrganization, organizations) => {
  // const filterOrganizations = organizations.filter(organization => organization.id !== selectedOrganization.id);
  return (
    organizations.map(organization => ({
      key: organization.id,
      selected: false,
      as: 'a',
      href: `/dashboard?organization=${organization.id}`,
      text: (
        <div className="header-dropdown">
          <Avatar
            size={28}
            name={organization.fullname}
            src={organization.picture}
            maxInitials={2}
            round="50%"
            style={{ marginRight: '5px' }}
          />
          {organization.fullname}
        </div>
      )
    }))
  );
};

@inject('store')
@observer
class HeadMenu extends React.Component {
  name = 'HeadMenu';

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }

  render() {
    const {
      loading, error
    } = this.state;
    const { props } = this;
    const { user, selectedOrganization, organizations } = props.store;
    const { pathname } = props.router;
    if (loading) {
      return (
        <div className="header-container">
          <Loading width={25} height={25} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="header-container" style={{ justifyContent: 'center' }}>
          <span>{error}</span>
        </div>
      );
    }

    const organization_options = organizationOptions(selectedOrganization, organizations);
    const user_options = userOptions(props.router.pathname, user);

    return (
      <FadeIn>
        <Menu fixed="top" borderless>
          <Container fluid className="header-container">
            <Menu.Item className="header-item-left" header>
              <Dropdown
                // trigger={organizationTrigger(selectedOrganization)}
                // options={organization_options}
                pointing="top left"
                icon="bars"
              >
                <Dropdown.Menu>
                  <Dropdown.Header style={{ color: '#8a8a8a', paddingLeft: 24, paddingTop: 10 }}>
                    {__('Escolas')}
                  </Dropdown.Header>
                  {organization_options.map(o => (<Dropdown.Item {...o} />))}
                  <Dropdown.Header style={{ color: "#8a8a8a", paddingLeft: 24 }}>
                    {__('Opções')}
                  </Dropdown.Header>
                  {user_options.map(o => (<Dropdown.Item {...o} />))}
                </Dropdown.Menu>
              </Dropdown>
              {(pathname === '/dashboard') ? (
                <span>
                  <Avatar
                    size={28}
                    name={selectedOrganization.fullname}
                    maxInitials={2}
                    src={selectedOrganization.picture}
                    round="50%"
                    style={{ marginRight: '5px', marginLeft: 16 }}
                  />
                  {selectedOrganization.fullname}
                </span>
              ) : null}
            </Menu.Item>
            <Menu.Item className="header-item" as="a" header href="/dashboard">
              <Image className="logo" src={asset('logo-h.png')} />
            </Menu.Item>
            <Menu.Item className="header-item-right" header>
            </Menu.Item>
          </Container>
        </Menu>
        <div className="header-sub" />
      </FadeIn >
    );
  }
}

export default withRouter(HeadMenu);
